import {
  CODE_BUNDLE_MAIN_TAXON,
  URL_PAGE_ALC,
  URL_PAGE_CART,
  URL_PAGE_DURATION,
  URL_PAGE_MEALS,
  URL_PAGE_MEN,
  URL_PAGE_REGIME,
  URL_PAGE_STAB_MEALS,
  URL_PAGE_STABILISATION,
  URL_PAGE_WOMEN,
} from "@middleware/constants";
import { isStabOrder } from "@middleware/helpers";
import { useCart } from "@middleware/hooks/useCart";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { UrlObject } from "url";
import { useAlc } from "./useAlc";

export const useSteps = () => {
  const { pathname } = useRouter();
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const [previousStepsUrl, setPreviousStepsUrl] = useState<
    (string | UrlObject)[]
  >([]);
  const { cart } = useCart();
  const { isAlc } = useAlc(cart);
  const bundleCode =
    cart && cart.items.length > 0
      ? cart.items.find(
          (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON,
        )?.variantCode
      : "";

  const isStab = isStabOrder(cart);

  useEffect(() => {
    switch (pathname) {
      case URL_PAGE_DURATION:
      case URL_PAGE_REGIME:
      case URL_PAGE_MEN:
      case URL_PAGE_WOMEN:
        setCurrentStep(1);
        setPreviousStepsUrl([]);
        break;
      case URL_PAGE_MEALS:
        setCurrentStep(2);
        setPreviousStepsUrl([URL_PAGE_DURATION]);
        break;
      case URL_PAGE_CART:
        setPreviousStepsUrl(
          isStab
            ? [
                URL_PAGE_STABILISATION,
                {
                  pathname: URL_PAGE_STAB_MEALS,
                  query: { code: bundleCode },
                },
              ]
            : isAlc === true
            ? [URL_PAGE_DURATION, URL_PAGE_ALC]
            : [
                URL_PAGE_DURATION,
                {
                  pathname: URL_PAGE_MEALS,
                  query: { code: bundleCode },
                },
              ],
        );
        setCurrentStep(3);
        break;
      default:
        setCurrentStep(null);
        setPreviousStepsUrl([]);
        break;
    }
  }, [pathname, bundleCode, isStab, isAlc]);

  return { currentStep, previousStepsUrl };
};
