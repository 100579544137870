import cn from "clsx";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { FC, MouseEventHandler, SyntheticEvent } from "react";
import { Link } from "@components/ui";
import { useAuth, useCart, useCustomer, useUI } from "@middleware/hooks";
import {
  URL_PAGE_AVIS,
  URL_PAGE_BILAN,
  URL_PAGE_CART,
  URL_PAGE_CUSTOMER_ORDERS_COMING,
  URL_PAGE_MEN,
  URL_PAGE_METHODE,
  URL_PAGE_STABILISATION,
  URL_PAGE_WOMEN,
} from "@middleware/constants";
import PictoPhone from "@static/images/header/phone-shape.svg";
import PictoAccount from "@static/images/header/mobile-user.svg";
import PictoCart from "@static/images/header/mobile-cart.svg";
import PictoWomen from "@static/images/header/picto-women.svg";
import PictoMen from "@static/images/header/picto-men.svg";
import PictoBilan from "@static/images/header/mobile-bilan.svg";
import PictoAvis from "@static/images/header/mobile-review.svg";
import PictoLogout from "@static/images/header/mobile-logout.svg";
import MethodLogout from "@static/images/header/mobile-method.svg";
import PictoStab from "@static/images/header/picto-stab.svg";
import { getIsProspect } from "@middleware/helpers";
import { Logo } from "../Logo/Logo";
import style from "./Menu.module.scss";

export interface Props {
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
}

export const MobileMenu: FC<Props> = ({ showModal, logout }) => {
  const { isAuthenticated } = useAuth();
  const { cart } = useCart();
  const { t } = useTranslation("header");
  const { closeDropdown } = useUI();
  const { customer } = useCustomer();
  const isProspect = getIsProspect(customer);
  const cmsPages = [
    {
      url: URL_PAGE_METHODE,
      label: t("menu.pMethode"),
      image: MethodLogout,
    },
  ];
  const programsPages = [
    {
      url: URL_PAGE_WOMEN,
      label: t("menu.pProgramWomen"),
      image: PictoWomen,
    },
    {
      url: URL_PAGE_MEN,
      label: t("menu.pProgramMen"),
      image: PictoMen,
    },
  ];
  const filtredProgramsPages = isProspect
    ? programsPages
    : programsPages.concat([
        {
          url: URL_PAGE_STABILISATION,
          label: t("menu.labelStab"),
          image: PictoStab,
        },
      ]);

  const othersPages = [
    {
      url: URL_PAGE_BILAN,
      label: t("menu.pBilan"),
      image: PictoBilan,
    },
    {
      url: URL_PAGE_AVIS,
      label: t("menu.pAvis"),
      image: PictoAvis,
    },
  ];

  const pages = cmsPages.concat(filtredProgramsPages, othersPages);

  return (
    <div className={style.mobileMenu}>
      <div className={style.logo}>
        <Link href="/" className="simple">
          <Logo type="footer" />
        </Link>
      </div>
      <ul>
        <li className={style.number}>
          <a href={`tel:${t("menu.pPhoneNum")}`}>
            <Image src={PictoPhone} alt="" />
            {isAuthenticated === true
              ? t("menu.pPhoneNumDotConnecte")
              : t("menu.pPhoneNumDotDeconnecte")}
            <span>{t("menu.appelLocal")}</span>
          </a>
        </li>
        <li>
          {isAuthenticated === true ? (
            <Link
              className={cn("simple", style.compteMobile)}
              href={URL_PAGE_CUSTOMER_ORDERS_COMING}
              onClick={closeDropdown}
            >
              <Image src={PictoAccount} alt="" />
              {t("menu.pMyAccount")}
            </Link>
          ) : (
            <a
              className={style.compteMobile}
              href="#!"
              onClick={(e) => showModal(e)}
            >
              <Image src={PictoAccount} alt="" />
              {t("menu.pMyAccount")}
            </a>
          )}
        </li>

        {cart && cart.total > 0 && (
          <li>
            <Link
              href={URL_PAGE_CART}
              className="simple"
              onClick={closeDropdown}
            >
              <Image src={PictoCart} alt="" />
              {t("menu.myCart")}
            </Link>
          </li>
        )}

        {pages.map((page, index) => (
          <li key={index}>
            <Link href={page.url} className="simple" onClick={closeDropdown}>
              <Image src={page.image} alt="" />
              {page.label}
            </Link>
          </li>
        ))}

        {isAuthenticated === true && (
          <li>
            <a href="#!" onClick={logout}>
              <Image src={PictoLogout} alt="" />
              {t("menu.pLogOut")}
            </a>
          </li>
        )}
      </ul>
      {isAuthenticated !== true && (
        <div className={style.fixeLink}>
          <Link className="primary" href="#!" onClick={(e) => showModal(e)}>
            {t("login.buttonConnexion")}
          </Link>
        </div>
      )}
    </div>
  );
};
