import cn from "clsx";
import React, { FC, ReactNode } from "react";

interface ContainerProps {
  className?: string;
  id?: string;
  children?: ReactNode;
  el?: HTMLElement;
  clean?: boolean;
}

export const Container: FC<ContainerProps> = ({
  children,
  className,
  id,
  el = "div",
  clean = false, // Full Width Screen
}) => {
  const rootClassName = cn(className, {
    "w-full": !clean,
  });

  // @ts-expect-error no error expected
  const Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el;

  return (
    <Component className={rootClassName} id={id}>
      {children}
    </Component>
  );
};
