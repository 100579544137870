import cn from "clsx";
import { FC, ReactNode } from "react";
import Image from "next/image";
import { TTitleLineStyle } from "@middleware/types";
import { getUnderlineTitle } from "@middleware/helpers/commun/titlePage";

interface Props {
  children: ReactNode;
  underline?: TTitleLineStyle;
  className?: string;
}

export const TitlePage: FC<Props> = ({ children, underline, className }) => {
  const underlineImage = underline ? getUnderlineTitle(underline) : undefined;

  return (
    <div className="title-page">
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={cn("lk-blc-title-s1 center", className)}>
            {children}
            {underlineImage !== undefined && (
              <Image alt="" src={underlineImage} width={100} height={10} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
