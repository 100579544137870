import cn from "clsx";
import Image, { StaticImageData } from "next/image";
import { FC, ReactNode, useState } from "react";
import Button from "../Button/Button";
import { Text } from "../Text/Text";
import style from "./Collapse.module.scss";

interface Props {
  isActive: boolean;
  title: string;
  children: ReactNode;
  className?: string;
  icon?: StaticImageData;
}

export const Collapse: FC<Props> = ({
  title,
  children,
  className,
  icon,
  isActive,
}) => {
  const [showTab, setShowTab] = useState(isActive);

  return (
    <div className={cn(className, style.toogler, { [style.active]: showTab })}>
      <Button
        type="button"
        puce={false}
        onClick={() => setShowTab(!showTab)}
        data-testid="toggle-button"
      >
        {icon !== undefined && (
          <Image alt="" src={icon} width="45" height="45" />
        )}
        <Text variant="span">{title}</Text>
      </Button>
      {showTab && <div className={style.subTab}>{children}</div>}
    </div>
  );
};
