import Image from "next/image";
import cn from "clsx";
import { Text } from "@components/ui";
import { useTranslation } from "next-i18next";
import { useAuth } from "@middleware/hooks";
import Picto from "@static/images/icons/animate-phone-menu.gif";
import style from "./PictoPhone.module.scss";

export const PictoPhone = () => {
  const { t } = useTranslation("header");
  const { isAuthenticated } = useAuth();

  return (
    <div className={cn(style.phone, { [style.connected]: isAuthenticated })}>
      <a href={`tel:${t("menu.pPhoneNum")}`}>
        <Image
          src={Picto}
          alt=""
          className={style.pictoPhone}
          width={55}
          height={35}
          unoptimized
        />
        {isAuthenticated === true
          ? t("menu.pPhoneNumDotConnecte")
          : t("menu.pPhoneNumDotDeconnecte")}
        <Text variant="span">{t("menu.callPrice")}</Text>
      </a>
    </div>
  );
};
