import React, { FC, useCallback, useMemo } from "react";
import {
  CLOSE_DROPDOWN,
  CLOSE_MODAL,
  HIDE_LOADER,
  OPEN_DROPDOWN,
  OPEN_MODAL,
  SET_MODAL_VIEW,
  SHOW_LOADER,
} from "@middleware/constants";
import { UIContext } from "@middleware/contexts";
import { contextUiInitialState } from "@middleware/init";
import { uiReducer } from "@middleware/reducers";
import { IProduct, MODAL_VIEWS, Props } from "@middleware/types";

export const UIProvider: FC<Props> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, contextUiInitialState);

  const showLoader = useCallback(
    (message?: string) => dispatch({ type: SHOW_LOADER, message }),
    [dispatch],
  );

  const hideLoader = useCallback(
    () => dispatch({ type: HIDE_LOADER }),
    [dispatch],
  );

  const openDropdown = useCallback(
    () => dispatch({ type: OPEN_DROPDOWN }),
    [dispatch],
  );
  const closeDropdown = useCallback(
    () => dispatch({ type: CLOSE_DROPDOWN }),
    [dispatch],
  );

  const openModal = useCallback(
    () => dispatch({ type: OPEN_MODAL }),
    [dispatch],
  );
  const closeModal = useCallback(
    () => dispatch({ type: CLOSE_MODAL }),
    [dispatch],
  );

  const setModalView = useCallback(
    (view: MODAL_VIEWS, product: IProduct | undefined = undefined) =>
      dispatch({ type: SET_MODAL_VIEW, view, product }),
    [dispatch],
  );

  const value = useMemo(
    () => ({
      ...state,
      showLoader,
      hideLoader,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
    }),
    [state], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <UIContext.Provider value={value} {...props} />;
};
