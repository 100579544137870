import Api from "@middleware/api";
import { IOrder } from "@middleware/types";
import { useEffect, useState } from "react";

export const useOrder = (
  shipmentOrderTokenValue: string | undefined,
  isOptimum = false,
) => {
  const [order, setOrder] = useState<IOrder>();

  useEffect(() => {
    const getOrder = async () => {
      if (shipmentOrderTokenValue === undefined) return false;

      if (shipmentOrderTokenValue.length === 0) return false;
      const customerOrder = isOptimum
        ? await Api.cart.getCartByTokenOfOptimum(shipmentOrderTokenValue)
        : await Api.cart.getCartByToken(shipmentOrderTokenValue, false);
      if (Array.isArray(customerOrder.items)) setOrder(customerOrder);
      else {
        customerOrder.items = Object.values(customerOrder.items);
        setOrder(customerOrder);
      }
    };
    void getOrder();
  }, [shipmentOrderTokenValue, isOptimum]);

  return { order, setOrder };
};
