import { REMOVE_DETAILS, SET_DETAILS } from "@middleware/constants";
import { globalStatusInitialState } from "@middleware/init";
import { ActionType, IGlobalStatus } from "@middleware/types";

export const messagingReducer = (state: IGlobalStatus, action: ActionType) => {
  switch (action.type) {
    case SET_DETAILS:
      return {
        message: action.message,
      };
    case REMOVE_DETAILS:
      return {
        message: globalStatusInitialState.message,
      };
    default:
      throw new Error(`Unhandled action type`);
  }
};
