import { FC } from "react";
import { SEO } from "@components/commun";

interface Props {
  title?: string;
  description?: string;
}

export const Head: FC<Props> = ({ title, description }) => {
  return (
    <SEO title={title} description={description}>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
    </SEO>
  );
};
