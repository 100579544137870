import { useRouter } from "next/router";
import { useEffect } from "react";
import { URL_PAGE_CUSTOMER } from "@middleware/constants";
import { useAuth } from "./useAuth";
import { useUI } from "./useUI";

export const useAccount = () => {
  const { pathname } = useRouter();
  const { isAuthenticated } = useAuth();
  const { openModal, setModalView } = useUI();

  useEffect(() => {
    if (false === isAuthenticated && pathname.includes(URL_PAGE_CUSTOMER)) {
      openModal();
      setModalView("SIGNUP_VIEW");
    }

    // eslint-disable-next-line
  }, [isAuthenticated]);
};
