import { useContext, useEffect, useState } from "react";
import { AuthStateContext } from "@middleware/contexts";
import { StateAuth } from "@middleware/types";
import { authInitialState } from "@middleware/init";

export const useAuth = () => {
  const context = useContext<StateAuth>(AuthStateContext);
  const [authState, setAuthState] = useState(authInitialState);

  useEffect(() => {
    setAuthState({
      ...context,
      isAuthenticated:
        context.isAuthenticated === undefined ? false : context.isAuthenticated,
    });
  }, [context]);

  return authState;
};
