import { BIG_TABLET_SIZE, TABLET_SIZE } from "@middleware/constants";
import { useEffect, useState } from "react";

export const useWindow = () => {
  const defaultWidth = typeof window !== "undefined" ? window.innerWidth : 0;

  const [width, setWidth] = useState(defaultWidth);
  const [isMobile, setIsMobile] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("load", updateWidth);
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("load", updateWidth);
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    setIsMobile(width < TABLET_SIZE);
    setIsDesktop(width > BIG_TABLET_SIZE);
  }, [width]);

  return { width, isMobile, isDesktop };
};
