import cn from "clsx";
import { RadioElementProps } from "@middleware/types";
import style from "./RadioButton.module.scss";

export const Radio = ({
  label,
  id,
  disabled,
  defaultChecked,
  ...rest
}: RadioElementProps) => {
  return (
    <div className={cn(style.wrapper, { checkedRadio: defaultChecked })}>
      <input
        id={id}
        data-testid={id}
        type="radio"
        disabled={disabled}
        checked={defaultChecked}
        {...rest}
      />
      <label htmlFor={id} className={cn({ [style.disabled]: disabled })}>
        <span>{label}</span>
      </label>
    </div>
  );
};
