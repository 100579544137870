import cn from "clsx";
import React, {
  CSSProperties,
  FunctionComponent,
  JSXElementConstructor,
} from "react";
import s from "./Text.module.scss";

interface TextProps {
  variant?: Variant;
  dataTest?: string;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode | string;
  html?: string | React.ReactElement;
  onClick?: () => void;
}

type Variant =
  | "p"
  | "span"
  | "strong"
  | "small"
  | "em"
  | "strike"
  | "heading"
  | "body"
  | "pageHeading"
  | "sectionHeading"
  | "sectionSubHeading";

export const Text: FunctionComponent<TextProps> = ({
  style,
  className = "",
  variant = "body",
  dataTest = "",
  children,
  html,
  onClick,
}) => {
  const componentsMap: {
    [P in Variant]: React.ComponentType<string> | string;
  } = {
    p: "p",
    span: "span",
    small: "small",
    em: "em",
    strike: "strike",
    strong: "strong",
    body: "div",
    heading: "h1",
    pageHeading: "h1",
    sectionHeading: "h2",
    sectionSubHeading: "h3",
  };

  const Component: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | JSXElementConstructor<any>
    | React.ReactElement // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<any>
    | string = componentsMap[variant];

  const htmlContentProps =
    html !== undefined
      ? {
          dangerouslySetInnerHTML: { __html: html },
        }
      : {};

  return (
    <Component
      className={cn(
        s.root,
        {
          [s.body]: variant === "body",
          [s.heading]: variant === "heading",
          [s.pageHeading]: variant === "pageHeading",
          [s.sectionHeading]: variant === "sectionHeading",
        },
        "t_text",
        className,
      )}
      data-testid={dataTest}
      onClick={onClick}
      style={style}
      {...htmlContentProps}
    >
      {children}
    </Component>
  );
};
