import { EOrderType, OrderItemSelection } from "../commerce";

export type IApiType = "api";

export type queryParamsType = unknown | null;

export type IpathParams =
  | ({ [key: string]: string | number } | null)
  | undefined;

export type pathParamsType = {
  [key: string]: string | number;
} | null;

export interface IgetResourceUrl {
  (
    ressourceType: IApiType,
    endPointStart: string | null,
    ressourceLocator: string,
    pathParams?: pathParamsType,
  ): string;
}

export interface IRequest {
  responseURL: string;
}

export interface TBulkData {
  orderItems: OrderItemSelection[];
  promotionCouponCode: string | undefined | null;
  type: EOrderType;
}

export enum EResponseType {
  JSON = "json",
  BLOB = "blob",
  Text = "text",
}
