import { useEffect } from "react";
import axios from "axios";
import { setToStorage } from "@middleware/helpers/global/sessions";
import { API_IP, CLIENT_IP } from "@middleware/constants";

export const useClientIp = () => {
  useEffect(() => {
    const getMyIp = async () => {
      try {
        const res = await axios.get(API_IP);
        if (typeof res.data.ip === "string") {
          setToStorage(CLIENT_IP, res.data.ip);
        }
      } catch (error) {
        setToStorage(CLIENT_IP, "0.0.0.0");
      }
    };

    void getMyIp();
  }, []);
};
