import { getPublicParams } from "@middleware/helpers";
import { useEffect } from "react";
import { saveSessionParams } from "@middleware/helpers/global/sessions";

export const useSessions = () => {
  useEffect(() => {
    const params = getPublicParams();
    saveSessionParams(params);
  }, []);
};
