import { TAppointment, TDietitian } from "@middleware/types";
import { useEffect, useState } from "react";
import Api from "@middleware/api";
import { AxiosError, HttpStatusCode } from "axios";
import { useTranslation } from "next-i18next";
import { useCustomer } from "./useCustomer";
import { useGlobalMessaging } from "./useGlobalMessaging";

export const useAppointment = () => {
  const { customer } = useCustomer();
  const [appointment, setAppointment] = useState<TAppointment>();
  const { t } = useTranslation();
  const [canMakeAppointment, setCanMakeAppointment] = useState<boolean>(false);
  const [hasAppointment, setHasAppointment] = useState<boolean>(false);
  const [dietitian, setDietitian] = useState<TDietitian | null>(null);
  const { setMessage } = useGlobalMessaging();

  const getAppointment = async () => {
    if (!customer) return;

    const appointmentData = await Api.msDiet.getAppointment(customer.id);
    if (appointmentData !== undefined) {
      setAppointment(appointmentData.currentAppointment);
      setCanMakeAppointment(appointmentData.canTake);
      setHasAppointment(appointmentData.hasCurrentAppointment);
      setDietitian(appointmentData.diet);
    }
  };

  useEffect(() => {
    if (customer) void getAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id]);

  const makeAppointment = async (
    date: string,
  ): Promise<boolean | AxiosError> => {
    if (customer === undefined) return false;
    if (!canMakeAppointment) {
      setMessage({
        text: t("error.canNotTakeAppointment"),
        type: "ERROR",
      });

      return false;
    }

    const result = await Api.msDiet.makeAppointment(date, customer.id);
    if (result.status === HttpStatusCode.Ok) {
      await getAppointment();

      return true;
    }

    return result.data;
  };
  const cancelAppointment = async () => {
    if (customer && appointment?.id !== undefined) {
      const response = await Api.msDiet.cancelAppointment(
        appointment.id,
        customer.id,
      );
      if (response.status !== 200) {
        return false;
      }
      await getAppointment();

      return true;
    }

    return false;
  };

  return {
    customer,
    dietitian,
    appointment,
    hasAppointment,
    makeAppointment,
    cancelAppointment,
    canMakeAppointment,
  };
};
