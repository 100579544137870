import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { ISlider } from "@middleware/types";
import style from "./Slider.module.scss";

export const SliderForm: FC<ISlider> = ({
  control,
  name,
  error,
  label,
  wrapperClass,
  min,
  marks,
  defaultValue,
  startLabel,
  endLabel,
}) => {
  return (
    <div className={style.root}>
      <div className={wrapperClass}>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <fieldset>
                <legend>{label}</legend>
                <div>
                  <span className={style.startLabel}>{startLabel}</span>
                  <Slider
                    min={min}
                    defaultValue={defaultValue as number}
                    marks={marks}
                    step={null}
                    value={value as number}
                    handleStyle={{
                      borderColor: "#5a008c",
                      opacity: 1,
                      zIndex: 9,
                    }}
                    railStyle={{ backgroundColor: "#c5c5c5" }}
                    dotStyle={{ visibility: "hidden" }}
                    trackStyle={{ backgroundColor: "#5a008b" }}
                    onChange={(nextValues) => {
                      onChange(nextValues);
                    }}
                  />
                  <span className={style.endLabel}>{endLabel}</span>
                </div>
              </fieldset>
            );
          }}
        />
      </div>
      {error !== undefined && (
        <span role="alert" className="erreur-field atp">
          {error}
        </span>
      )}
    </div>
  );
};
