import { ESubscriptionState, ICustomer, IOrder } from "@middleware/types";
import { useEffect, useState } from "react";
import { calculateAge } from "@middleware/helpers";
import { MIN_AGE_SEPA, SEPA_SESSION_CODE } from "@middleware/constants";
import { setToStorage } from "@middleware/helpers/global/sessions";
import { useAuth } from "./useAuth";
import { useCart } from "./useCart";

export const useCustomer = () => {
  const { user, customer } = useAuth();
  const { cart } = useCart();
  const [currentCustomer, setCustomer] = useState<ICustomer>();
  const [currentOrder, setCurrentOrder] = useState<IOrder>();

  useEffect(() => {
    const getCustomer = async () => {
      if (user && customer) {
        const newCustomer = { ...customer, id: user.id };
        setCustomer(newCustomer);
        if (
          customer.currentSubscription &&
          customer.currentSubscription.state !== ESubscriptionState.CLOSED
        ) {
          if (
            customer.currentSubscription.state === ESubscriptionState.ON_GOING
          ) {
            setCurrentOrder(customer.currentSubscription.firstUpcomingOrder);
          } else {
            setCurrentOrder(cart);
          }
        }

        if (customer.birthday !== undefined) {
          const age = calculateAge(customer.birthday);
          if (age >= MIN_AGE_SEPA) setToStorage(SEPA_SESSION_CODE, "true");
        }
      }
    };

    void getCustomer();
  }, [user, customer, cart]);

  return { customer: currentCustomer, setCustomer, currentOrder };
};
