import cn from "clsx";
import { Loader } from "@components/ui";
import { Text } from "@components/ui";
import { useUI } from "@middleware/hooks";
import style from "../LoadingSite/LoadingSite.module.scss";

export const LoadingPage = () => {
  const { isLoading, messageLoading } = useUI();

  return (
    <>
      {isLoading && (
        <div className={cn(style.iplProgressIndicator, style.transpIndicator)}>
          <div className={style.inspLogoFrame}>
            <div className={style.loader}>
              <Loader type="clip" />
              {messageLoading !== "" && (
                <Text variant="p" html={messageLoading} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
