import { FC, MouseEventHandler, SyntheticEvent } from "react";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";

export interface Props {
  device: "desktop" | "mobile";
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
  isFullScreenPage?: boolean;
}

export const Menu: FC<Props> = ({
  device,
  showModal,
  logout,
  isFullScreenPage = false,
}) => {
  return (
    <>
      {device === "desktop" ? (
        !isFullScreenPage && <DesktopMenu />
      ) : (
        // Mobile Menu
        <MobileMenu showModal={showModal} logout={logout} />
      )}
    </>
  );
};
