import {
  CLOSE_DROPDOWN,
  CLOSE_MODAL,
  HIDE_LOADER,
  OPEN_DROPDOWN,
  OPEN_MODAL,
  SET_MODAL_VIEW,
  SHOW_LOADER,
} from "@middleware/constants";
import { ActionUi, StateUi } from "@middleware/types";

export function uiReducer(state: StateUi, action: ActionUi) {
  switch (action.type) {
    case SHOW_LOADER: {
      return {
        ...state,
        isLoading: true,
        messageLoading: action.message,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case OPEN_DROPDOWN: {
      return {
        ...state,
        displayDropdown: true,
      };
    }
    case CLOSE_DROPDOWN: {
      return {
        ...state,
        displayDropdown: false,
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        displayModal: true,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        displayModal: false,
      };
    }
    case SET_MODAL_VIEW: {
      return {
        ...state,
        modalView: action.view,
        productToShow: action.product,
      };
    }
  }
}
