import WrapApi from "@middleware/helpers/api/wrapApi.class";
import { serializeCalendarData } from "@middleware/helpers/commun/calendar";
import { serializeAppointmentData } from "@middleware/helpers/global/agenda";
import {
  ICalendarDataApi,
  ICalendarDay,
  TAppointmentApiData,
  TDiet,
} from "@middleware/types";
import { AxiosResponse } from "axios";

export default class MsDiet extends WrapApi {
  getSlots = async (
    week: number,
    year: number,
    customerId: number,
  ): Promise<ICalendarDay[] | undefined> => {
    const result = await this.getResource(
      `shop/customers/${customerId}/diet/slots`,
      null,
      {
        week,
        year,
      },
    );

    if (result.status !== 200) return;

    return serializeCalendarData(result.data as ICalendarDataApi);
  };
  getAppointment = async (customerId: number): Promise<TDiet | undefined> => {
    const result = await this.getResource(`shop/customers/${customerId}/diet`);

    if (result.status !== 200) return;

    return serializeAppointmentData(result.data as TAppointmentApiData);
  };

  makeAppointment = async (
    date: string,
    customerId: number,
  ): Promise<AxiosResponse> => {
    return await this.postResource(
      `shop/customers/${customerId}/diet/make-appointment`,
      { date },
    );
  };

  cancelAppointment = async (
    appointmentId: string | number,
    customerId: number,
  ): Promise<AxiosResponse> => {
    return await this.postResource(
      `shop/customers/${customerId}/diet/cancel-appointment`,
      { appointment_id: appointmentId },
    );
  };
}
