import cn from "clsx";
import { Controller } from "react-hook-form";
import { IOption, IRadioGroup } from "@middleware/types";
import { Radio } from "../Radio/Radio";
import style from "./RadioButtonGroup.module.scss";

export const RadioButtonGroup = ({
  name,
  label,
  values,
  defaultValue,
  required,
  onChange,
}: IRadioGroup) => {
  function renderOptions() {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return values?.map(({ label, value, disabled }: IOption, index) => {
      const shortenedOptionLabel = label.replace(/\s+/g, "");
      const optionId = `radio-option-${shortenedOptionLabel}-${name}`;

      return (
        <Radio
          key={index}
          id={optionId}
          label={label}
          value={value as string}
          name={name}
          disabled={disabled}
          defaultChecked={value.toString() === defaultValue?.toString()}
          onChange={onChange}
        />
      );
    });
  }

  return (
    <fieldset className={style.fieldset}>
      <legend className={cn(style.legend, { "required-field": required })}>
        {label}
      </legend>
      <div className={style.Wrapper}>{renderOptions()}</div>
    </fieldset>
  );
};

export const RadioButtons = ({
  control,
  name,
  label,
  values,
  defaultValue,
  error,
  wrapperClass,
}: IRadioGroup) => {
  return (
    <div className={wrapperClass}>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RadioButtonGroup
                name={name}
                label={label}
                values={values}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.value)
                }
                defaultValue={
                  typeof value === "string" || typeof value === "number"
                    ? value
                    : defaultValue
                }
              />
            );
          }}
        />
      </div>
      {error !== undefined && (
        <span role="alert" className="erreur-field atp">
          {error}
        </span>
      )}
    </div>
  );
};
