import axios, { AxiosHeaders } from "axios";
import { AuthInfo } from "@middleware/types";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { client as env } from "@config/env/client";
import { authInitialState } from "@middleware/init";
import {
  isCustomerResponse,
  isFacebookResponse,
  isRefreshRequest,
  isRefreshResponse,
} from "../global/urls";
import { clearToStorage } from "../global/sessions";

const instanceAxios: AxiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
    "X-channel-code": env.NEXT_PUBLIC_CHANNEL_CODE,
    Accept: "application/json",
  },
  validateStatus: function (status) {
    return status >= 200 && status <= 503;
  },
});

const pendingRequests = new Map();
const requests: string[] = [];

instanceAxios.interceptors.request.use(
  (config) => {
    if (config.url !== undefined && config.method !== undefined) {
      const requestIdentifier = `${config.url}_${config.method}`;
      if (pendingRequests.has(requestIdentifier)) {
        requests.push(requestIdentifier);
      }
      if (requests.length > 6) {
        // multiples pending requests
        const cancelTokenSource = pendingRequests.get(requestIdentifier);
        cancelTokenSource.cancel("Cancelled due to multiples requests");
      }
      const newCancelTokenSource = axios.CancelToken.source();
      config.cancelToken = newCancelTokenSource.token;
      pendingRequests.set(requestIdentifier, newCancelTokenSource);
    }

    // check if we have user token
    if (typeof localStorage !== "undefined") {
      const userInfoStorage = localStorage.getItem("userInfo");
      if (userInfoStorage !== null) {
        const userInfo = JSON.parse(userInfoStorage) as AuthInfo;
        if (userInfo.user && !isRefreshRequest(config)) {
          const token = userInfo.user.token;
          (config.headers as AxiosHeaders).set(
            "Authorization",
            `Bearer ${token}`,
          );
        }
      }
    }

    return config;
  },
  (err: AxiosError) => {
    return Promise.reject(err);
  },
);

instanceAxios.interceptors.response.use(
  async (response: AxiosResponse) => {
    if (
      response.config.url !== undefined &&
      response.config.method !== undefined
    ) {
      const requestIdentifier = `${response.config.url}_${response.config.method}`;
      pendingRequests.delete(requestIdentifier);
    }

    if (response.status === 401) {
      if (
        typeof localStorage !== "undefined" &&
        !isFacebookResponse(response) &&
        !isRefreshResponse(response) &&
        !isCustomerResponse(response)
      ) {
        const loggedOutUserInfo = {
          isAuthenticated: authInitialState.isAuthenticated,
          user: authInitialState.user,
        };
        clearToStorage();
        localStorage.setItem("userInfo", JSON.stringify(loggedOutUserInfo));
      }

      return Promise.resolve(response);
    } else return Promise.reject(response);
  },
  (error: AxiosResponse<AxiosError>) => {
    if (error.config.url !== undefined && error.config.method !== undefined) {
      const requestIdentifier = `${error.config.url}_${error.config.method}`;
      pendingRequests.delete(requestIdentifier);
    }

    return Promise.reject(error);
  },
);

instanceAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status >= 200 && response.status < 300)
      return Promise.resolve(response);
    else return Promise.reject(response);
  },
  (error: AxiosResponse<AxiosError>) => {
    return Promise.reject(error);
  },
);

export default instanceAxios;
