import { Content } from "@prismicio/client";

export const sortReviews = (reviews: Content.AvisDocument[]) => {
  reviews.sort((a, b) => {
    if (a.data.position === null && b.data.position === null) return 0;
    if (a.data.position === null) return 1;
    if (b.data.position === null) return -1;

    return a.data.position - b.data.position;
  });

  return reviews;
};
