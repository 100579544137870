import { Tab } from "@middleware/types/global/tabType";
import { useEffect, useState } from "react";

export const useStepsTabs = (defaultStep: Tab, steps: Tab[]) => {
  const [tabs, setTabs] = useState<Tab[]>(steps);
  const [prev, setPrev] = useState<Tab>();
  const [currentTab, setCurrentTab] = useState<Tab>(defaultStep);
  const [isLastTab, setIsLastTab] = useState<boolean>(false);

  const getCurrentIndex = (): number => {
    return tabs.findIndex((tab) => tab.id === currentTab.id);
  };
  useEffect(() => {
    const currentIndex = getCurrentIndex();
    if (currentIndex === tabs.length - 1) {
      setIsLastTab(true);
    } else {
      setIsLastTab(false);
    }
    if (currentIndex === 0) {
      setPrev(undefined);
    } else {
      setPrev(tabs[currentIndex - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, currentTab]);

  const setCurrentTabAsVisited = () => {
    setTabs((prevTabs) =>
      prevTabs.map((prevTab) => {
        if (prevTab.id === currentTab.id) {
          return { ...prevTab, visited: true };
        }

        return prevTab;
      }),
    );
  };

  const setNextTab = () => {
    if (isLastTab) return;
    setCurrentTabAsVisited();
    const nextIndex = getCurrentIndex() + 1;
    setCurrentTab(tabs[nextIndex]);
  };

  const setPrevTab = () => {
    if (getCurrentIndex() === 0) return;
    const prevIndex = getCurrentIndex() - 1;
    setCurrentTab(tabs[prevIndex]);
  };

  const setActiveTab = (tab: Tab) => {
    setCurrentTabAsVisited();
    setCurrentTab(tab);
  };

  return {
    currentTab,
    tabs,
    setActiveTab,
    setNextTab,
    prev,
    setPrevTab,
    isLastTab,
  };
};
