import Api from "@middleware/api";
import { EStateOrder, IOrder } from "@middleware/types";
import { useEffect, useState } from "react";

export const useOrders = (states?: string) => {
  const [orders, setOrders] = useState<IOrder[]>();
  const [hasOrders, setHasOrders] = useState<boolean>();

  useEffect(() => {
    const defaultValidOrdersState = [
      EStateOrder.ORDER_STATE_FULFILLED,
      EStateOrder.ORDER_STATE_NEW,
    ];
    const validOrdersState =
      states !== undefined
        ? [states, EStateOrder.ORDER_STATE_SKIPPED]
        : defaultValidOrdersState;

    const getOrders = async () => {
      const customerOrders = await Api.cart.getCustomerOrders();
      const validOrders = customerOrders.filter((order) =>
        validOrdersState.includes(order.state),
      );
      setOrders(validOrders);
      setHasOrders(validOrders.length > 0);
    };
    void getOrders();
  }, [states]);

  return { hasOrders, orders, setOrders };
};
