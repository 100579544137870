import { ValueOf } from "next/dist/shared/lib/constants";

export type Props = {
  children?: React.ReactNode;
};
export type TReferral = {
  email: string;
  status: "pending" | "customer";
  date: string;
  commandDate: string;
};
export type TReferralListResponse = {
  email: string;
  sponsoredAt: string;
  customerSince: string;
  state: string;
};
export enum EGender {
  MAN = "man",
  WOMAN = "woman",
}
export enum EGenderValue {
  MAN = "gender_a",
  WOMAN = "gender_b",
}
export type TGender = ValueOf<typeof EGender>;
export type TGenderValue = ValueOf<typeof EGenderValue>;
