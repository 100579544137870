import { ThemeProvider } from "next-themes";
import React, { FC } from "react";
import { contextUiInitialState } from "@middleware/init";
import { UIProvider } from "@middleware/providers";
import { Props, StateUi } from "@middleware/types";

export const UIContext = React.createContext<StateUi>(contextUiInitialState);

export const ManagedUIProvider: FC<Props> = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
);
