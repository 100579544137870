import { ETitleLineStyles, TTitleLineStyle } from "@middleware/types";
import Underline1 from "@static/images/icons/underlines-1.png";
import Underline2 from "@static/images/icons/underlines-2.png";
import Underline3 from "@static/images/icons/underlines-3.png";
import Underline4 from "@static/images/icons/underlines-6.png";
import Underline5 from "@static/images/icons/underlines-7.svg";
import Underline6 from "@static/images/icons/underlines-8.png";

const underlineStyles = {
  [ETitleLineStyles.STYLE_1]: Underline1.src,
  [ETitleLineStyles.STYLE_2]: Underline2.src,
  [ETitleLineStyles.STYLE_3]: Underline3.src,
  [ETitleLineStyles.STYLE_4]: Underline4.src,
  [ETitleLineStyles.STYLE_5]: Underline5.src,
  [ETitleLineStyles.STYLE_6]: Underline6.src,
};

export const getUnderlineTitle = (underlineParam: TTitleLineStyle) =>
  underlineStyles[underlineParam];
