import React, { FunctionComponent } from "react";
import styles from "./Badge.module.scss";

interface IBadge {
  children: React.ReactNode | string;
}

export const Badge: FunctionComponent<IBadge> = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};
