export { default as sleep } from "./global/sleep";
export * from "./global/urls";
export * from "./global/router";
export * from "./global/utils";
export * from "./global/cart";
export * from "./global/customer";
export * from "./global/phone";
export * from "./global/dietitian";
export * from "./global/credits";
export * from "./global/reviews";
export * from "./global/catalog";
