import { LAST_CART_TOKEN_STORAGE } from "@middleware/constants";
import { setToStorage } from "@middleware/helpers/global/sessions";
import { getFromStorage } from "./../helpers/global/sessions";

export const useLastPaidOrder = () => {
  const setLastPaidOrderToken = (token: string) =>
    setToStorage(LAST_CART_TOKEN_STORAGE, token);

  const lastPaidOrderToken = getFromStorage(LAST_CART_TOKEN_STORAGE) as string;

  return { lastPaidOrderToken, setLastPaidOrderToken };
};
