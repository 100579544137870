import { IOrder, IOrderItem } from "@middleware/types";
import {
  BUNDLE_CODE_MEALS,
  CODE_BUNDLE_MAIN_TAXON,
  CODE_MEAL_MAIN_TAXON,
  URL_PAGE_DURATION,
  URL_PAGE_MEALS,
} from "@middleware/constants";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getAttributeFromorderItem } from "@middleware/helpers";
import { useAlc } from "./useAlc";

export const useBundle = (cart: IOrder | undefined) => {
  const { isAlc } = useAlc(cart);
  const [isValidBundle, setValidBundle] = useState(false);
  const [mealsNumber, setMealsNumber] = useState(0);
  const [bundleCartItem, setBundleCartItem] = useState<IOrderItem>();
  const router = useRouter();

  useEffect(() => {
    if (cart) {
      const currentBundle = {
        exist: false,
        mealsNumber: 0,
        meals: 0,
        code: "",
      };

      const validate = async () => {
        cart.items.map((item) => {
          if (item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON) {
            currentBundle.exist = true;
            const numberFormulaMeals =
              getAttributeFromorderItem(item, BUNDLE_CODE_MEALS) ?? 0;
            currentBundle.mealsNumber = numberFormulaMeals as number;
            currentBundle.code = item.variantCode;
            setMealsNumber(numberFormulaMeals as number);
            setBundleCartItem(item);
          } else if (item.productTypeTaxon === CODE_MEAL_MAIN_TAXON) {
            currentBundle.meals += item.quantity;
          }

          return true;
        });

        if (isAlc === false) {
          if (!currentBundle.exist) {
            await router.push(URL_PAGE_DURATION);
          } else if (
            currentBundle.mealsNumber === 0 ||
            currentBundle.mealsNumber !== currentBundle.meals
          ) {
            const codeBundle = currentBundle.code;
            await router.push({
              pathname: URL_PAGE_MEALS,
              query: { code: codeBundle },
            });
          } else {
            setValidBundle(true);
          }
        } else {
          setValidBundle(true);
        }
      };

      void validate();
    }
  }, [cart, isAlc, router]);

  return { isValidBundle, mealsNumber, bundleCartItem };
};
