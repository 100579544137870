import { DEFAULT_ORDER_ADDRESS } from "@middleware/constants";
import { getUseDifferentBillingAddressDefaultValue } from "@middleware/helpers";
import { IOrder } from "@middleware/types";

export const useAddressesInitialValues = (cart: IOrder) => {
  let defaultValues = DEFAULT_ORDER_ADDRESS;

  if (cart.shippingAddress && cart.billingAddress) {
    defaultValues = {
      ...defaultValues,
      shippingAddress: cart.shippingAddress,
      billingAddress: cart.billingAddress,
      useDifferentBillingAddress:
        getUseDifferentBillingAddressDefaultValue(cart),
    };
  }

  return { defaultValues };
};
