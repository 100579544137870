import { clientEnv, clientSchema } from "./schema";

clientSchema.validate(clientEnv).catch(function (err) {
  console.error("❌ Invalid environment variables:\n", err);
  throw new Error("Invalid environment variables");
});

for (const key of Object.keys(clientEnv)) {
  if (!key.startsWith("NEXT_PUBLIC_")) {
    console.warn(
      `❌ Invalid public environment variable name: ${key}. It must begin with 'NEXT_PUBLIC_'`,
    );
    throw new Error("Invalid public environment variable name");
  }
}

export const client = clientEnv;
