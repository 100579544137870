import { useEffect, useState } from "react";
import { EOrderType, IOrder } from "@middleware/types";

export const useAlc = (cart: IOrder | undefined) => {
  const [isAlc, setIsAlc] = useState<boolean>();

  useEffect(() => {
    if (cart) {
      if (cart.type === EOrderType.ALC) {
        setIsAlc(true);
      } else {
        setIsAlc(false);
      }
    }
  }, [cart]);

  return { isAlc, items: cart?.items ?? [] };
};
