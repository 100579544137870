import { FC } from "react";
import Image from "next/image";
import style from "./NutriscoreImage.module.scss";

interface Props {
  nutriscore: string | undefined;
}
interface imagePath {
  path: string;
  alt: string;
}

export const NutriscoreImage: FC<Props> = ({ nutriscore }) => {
  const getPreferenceImage = () => {
    switch (nutriscore) {
      case "A":
        return {
          path: "/static/images/icons/Nutri-score-A.svg",
          alt: "A",
        };
      case "B":
        return {
          path: "/static/images/icons/Nutri-score-B.svg",
          alt: "B",
        };
      case "C":
        return {
          path: "/static/images/icons/Nutri-score-C.svg",
          alt: "C",
        };
      case "D":
        return {
          path: "/static/images/icons/Nutri-score-D.svg",
          alt: "D",
        };
      case "E":
        return {
          path: "/static/images/icons/Nutri-score-E.svg",
          alt: "E",
        };

      default:
        return null;
    }
  };

  const imageNutriscore: imagePath | null = getPreferenceImage();

  return (
    <div className={style.nutriscore}>
      {imageNutriscore !== null && (
        <Image
          src={imageNutriscore.path}
          width={64}
          height={34}
          alt={imageNutriscore.alt}
        />
      )}
    </div>
  );
};
