import { MouseEvent, SyntheticEvent } from "react";
import { Menu, NavBar } from "@components/commun";
import { Container } from "@components/ui";
import { useClearAutentification, useSteps, useUI } from "@middleware/hooks";
import { URL_HOME } from "@middleware/constants";
import { Hamburger } from "./Hamburger/Hamburger";
import style from "./Header.module.scss";

interface Props {
  isFullScreenPage?: boolean;
}
export const Header = ({ isFullScreenPage = false }: Props) => {
  const { resetAutentification } = useClearAutentification();
  const {
    openModal,
    setModalView,
    displayDropdown,
    closeDropdown,
    showLoader,
    hideLoader,
  } = useUI();
  const { currentStep } = useSteps();
  const isUserInProgramFunnel = currentStep !== null;

  const showAuthentication = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    openModal();
    setModalView("SIGNUP_VIEW");
    closeDropdown();
  };

  const clearAutentification = async (event: SyntheticEvent) => {
    event.preventDefault();
    showLoader();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
      await resetAutentification();
      window.location.href = URL_HOME;
      hideLoader();
    }, 4000);
  };

  return (
    <>
      <header id="lk-header" className={style.header} role="columnheader">
        <Container>
          <div className={`lk-wrap ${style.wrap}`}>
            <div className="lk-flex flex-wrap space-between">
              <Hamburger />
              <NavBar
                showModal={showAuthentication}
                logout={clearAutentification}
                isUserInProgramFunnel={isUserInProgramFunnel}
                isFullScreenPage={isFullScreenPage}
              />
            </div>
          </div>
        </Container>
      </header>
      {displayDropdown && (
        <Menu
          device="mobile"
          showModal={showAuthentication}
          logout={clearAutentification}
        />
      )}
    </>
  );
};
