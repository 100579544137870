import {
  BUNDEL_MEALS_CODE,
  MAX_OPTIMUM_MEALS,
  OPTIMUM_DEFAULT_SCORE,
} from "@middleware/constants";
import { getOrderBundleItem } from "@middleware/helpers";
import {
  IControl,
  IOptimumRecommendations,
  IOrder,
  IProduct,
  ITabControl,
} from "@middleware/types";
import { Tab } from "@middleware/types/global/tabType";
import { useEffect, useMemo, useState } from "react";

export const useProgram = (
  products: IProduct[],
  order: IOrder | undefined,
  tabs: Tab[],
  activeTab: Tab,
  mealsNumber: number[] = [0],
  recommendations?: IOptimumRecommendations | undefined,
  disableDistributionQuantity?: boolean,
  bundleCode?: string,
) => {
  const isSameFormula = order
    ? bundleCode !== undefined
      ? getOrderBundleItem(order)?.variantCode === bundleCode
      : true
    : true;

  const getProductsByTab = (tab: Tab, productsParam: IProduct[] = []) => {
    return (productsParam.length > 0 ? productsParam : products).filter(
      (product) =>
        product.taxons?.some(
          (taxon) => taxon.toLowerCase() === tab.id.toString().toLowerCase(),
        ),
    );
  };
  const initControl: ITabControl[] = useMemo(() => {
    const controls: ITabControl[] = [];
    tabs.reduce((initControls: ITabControl[], tab) => {
      const tabProducts = getProductsByTab(tab);

      const control = {
        tab: tab,
        control: {
          products: tabProducts.map((product) => ({
            id: product.id,
            quantity:
              recommendations && tab.id === BUNDEL_MEALS_CODE
                ? recommendations.recommendation.find(
                    (item) => item.id === product.code,
                  )?.quantity ?? 0
                : (order?.items.find(
                    (item) => item.variantCode === product.codeVariant,
                  )?.quantity ?? 0) * product.packaging,
            code: product.codeVariant,
            price: product.price,
            packaging: product.packaging,
            enabled: product.enabled,
          })),
        },
      };

      initControls.push(control);

      return initControls;
    }, controls);

    return controls;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, products]);

  const [controls, setControls] = useState<ITabControl[]>(initControl);
  const [score, setScore] = useState<number>(OPTIMUM_DEFAULT_SCORE);

  const resetControl = () => {
    setControls((prevControls) =>
      prevControls.map((prevControl) => {
        if (prevControl.tab.id === activeTab.id) {
          const updatedControl: ITabControl = {
            tab: prevControl.tab,
            control: {
              products: getProductsByTab(prevControl.tab).map((product) => ({
                ...product,
                quantity: 0,
                code: product.codeVariant,
              })),
            },
          };

          return updatedControl;
        }

        return prevControl;
      }),
    );
  };

  const updateQuantityOnProductsFiltered = (currentProducts: IProduct[]) => {
    setControls((prevTabControls) => {
      return prevTabControls.map((prevTabControl) => {
        if (prevTabControl.tab.id === activeTab.id) {
          return {
            ...prevTabControl,
            control: {
              products: currentProducts.map((product) => {
                return {
                  ...product,
                  quantity:
                    prevTabControl.control.products.find(
                      (prod) => prod.id === product.id,
                    )?.quantity ?? 0,
                  code: product.codeVariant,
                };
              }),
            },
          };
        }

        return prevTabControl;
      });
    });
  };

  const setQuantity = (idProduct: number, quantity: number) => {
    const curentControl = controls.find(
      (control) => control.tab.id === activeTab.id,
    )?.control;

    if (!curentControl) {
      return;
    }

    curentControl.products.map((product, i) => {
      if (product.id === idProduct) {
        curentControl.products[i].quantity = quantity;
      }

      return true;
    });
    setControls((prevControls) =>
      prevControls.map((prevControl) =>
        prevControl.tab.id === activeTab.id
          ? { ...prevControl, control: curentControl }
          : prevControl,
      ),
    );
  };

  const setDefaultQuantity = (selectedProducts: IProduct[]) => {
    const newControls: ITabControl[] = [];
    tabs.map((tab) => {
      const formulas = tab.quantity;
      const tabProducts = getProductsByTab(tab, selectedProducts);
      const lengthProducts = tabProducts.length;

      if (lengthProducts === 0) return tab;

      const packaging = tabProducts[0].packaging;
      const defaultQty =
        Math.floor(formulas[0] / packaging / lengthProducts) * packaging;
      const remainer = Math.floor(
        (formulas[0] - lengthProducts * defaultQty) / packaging,
      );

      newControls.push({
        tab: tab,
        control: {
          products: tabProducts.map((product, index) => {
            return {
              ...product,
              quantity: defaultQty + (index < remainer ? 1 * packaging : 0),
              code: product.codeVariant,
            };
          }),
        },
      });

      return tab;
    });

    setControls(newControls);
  };

  const getCurrentControl = (tab: Tab): IControl => {
    return (
      controls.find((tabControl) => tabControl.tab.id === tab.id)?.control ?? {
        products: [],
      }
    );
  };

  const getRecommendedProductQty = (code: string): number => {
    return (
      products.find((item) => item.codeVariant === code)?.recommendedQuantity ??
      0
    );
  };

  useEffect(() => {
    const totalQuantity = initControl[0].control.products.reduce(
      (accum, item) => (accum += item.quantity),
      0,
    );
    if (
      mealsNumber[0] > 0 &&
      (totalQuantity !== mealsNumber[0] || !isSameFormula) &&
      disableDistributionQuantity !== true
    ) {
      setDefaultQuantity(products);

      return;
    }
    setControls(initControl);

    // pre-selection only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initControl]);

  useEffect(() => {
    const mealsControl = controls[0];
    const countOfBestMeals = mealsControl.control.products.reduce(
      (acc, p) =>
        (acc += getRecommendedProductQty(p.code) > 0 ? p.quantity : 0),
      0,
    );
    const countOfBadMeals = MAX_OPTIMUM_MEALS - countOfBestMeals;

    const newScore = Math.round(
      OPTIMUM_DEFAULT_SCORE - (40 * countOfBadMeals) / MAX_OPTIMUM_MEALS,
    );

    setScore(
      newScore > OPTIMUM_DEFAULT_SCORE ? OPTIMUM_DEFAULT_SCORE : newScore,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls]);

  return {
    controls,
    resetControl,
    updateQuantityOnProductsFiltered,
    setQuantity,
    getCurrentControl,
    score,
  };
};
