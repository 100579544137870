import cn from "clsx";
import Image from "next/image";
import React, { FC } from "react";
import Checkbox from "react-custom-checkbox";
import { Controller } from "react-hook-form";
import { InputProps } from "@middleware/types";
import icon from "@static/images/icons/checkbox_icon.png";

export const CheckboxForm: FC<InputProps> = ({
  control,
  name,
  error,
  label,
  required,
  wrapperClass,
  ...rest
}) => {
  return (
    <div className={wrapperClass}>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox
                onChange={(_value: boolean) => onChange(_value)}
                icon={<Image src={icon} style={{ width: 24 }} alt="" />}
                checked={value as boolean}
                label={label}
                borderColor="#5a008c"
                labelClassName={cn({ "required-field": required })}
                {...rest}
              />
            );
          }}
        />
      </div>
      {error !== undefined && (
        <span role="alert" className="erreur-field atp">
          {error}
        </span>
      )}
    </div>
  );
};
