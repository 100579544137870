import Image from "next/image";
import { MouseEventHandler, SyntheticEvent } from "react";
import { useAuth, useCustomer, useUI } from "@middleware/hooks";
import { getCustomerName } from "@middleware/helpers";
import { useTranslation } from "next-i18next";
import { Link } from "@components/ui";
import { URL_PAGE_CUSTOMER_ORDERS_COMING } from "@middleware/constants";
import Picto from "@static/images/header/account-shape.svg";
import style from "./PictoAccount.module.scss";

interface Props {
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
}

export const PictoAccount = ({ showModal, logout }: Props) => {
  const { t } = useTranslation("header");
  const { isAuthenticated } = useAuth();
  const { closeDropdown } = useUI();
  const { customer } = useCustomer();
  const showAccountInfo = isAuthenticated === true && customer !== undefined;

  return (
    <div className={style.account}>
      {showAccountInfo ? (
        <>
          <Link href={URL_PAGE_CUSTOMER_ORDERS_COMING} onClick={closeDropdown}>
            <Image src={Picto} alt="" />
            {getCustomerName(customer)}
          </Link>
          <ul className={style.submenu}>
            <li>
              <a href="#!" onClick={logout}>
                {t("menu.pDeconnexion")}
              </a>
            </li>
          </ul>
        </>
      ) : (
        <a
          className={style.compteMobile}
          href="#!"
          onClick={(e) => showModal(e)}
        >
          <Image src={Picto} alt="" />
          {t("menu.pMyAccount")}
        </a>
      )}
    </div>
  );
};
