import { IObject, IShemaMessage } from "@middleware/types";
import { useTranslation } from "next-i18next";

export const useSchemaMessages = (data?: IObject): IShemaMessage => {
  const { t } = useTranslation();
  const defaults = {
    minHeight: 80,
    maxHeight: 250,
    maxWeight: 300,
  };
  const defaultNamespace = {
    ...defaults,
    ...data,
    ns: "validation",
  };

  return {
    requiredField: t("requiredField", defaultNamespace),
    completeField: t("completeField", defaultNamespace),
    invalidField: t("invalidField", defaultNamespace),
    invalidEmail: t("invalidEmail", defaultNamespace),
    passwordMax: t("passwordMax", defaultNamespace),
    passwordMin: t("passwordMin", defaultNamespace),
    passwordNotSame: t("passwordNotSame", defaultNamespace),
    passwordConfirm: t("passwordConfirm", defaultNamespace),
    agreementCheck: t("agreementCheck", defaultNamespace),
    agreeTerms: t("agreeTerms", defaultNamespace),
    evaluationAgreementCheck: t("evaluationAgreementCheck", defaultNamespace),
    champsInvalide: t("champsInvalide", defaultNamespace),
    minHeight: t("minHeight", defaultNamespace),
    maxHeight: t("maxHeight", defaultNamespace),
    integerValue: t("integerValue", defaultNamespace),
    weightIsOver300Kg: t("weightIsOver300Kg", defaultNamespace),
    chooseLowerWeight: t("chooseLowerWeight", defaultNamespace),
    imcMustBeLessThan18: t("imcMustBeLessThan18", defaultNamespace),
    numberPositive: t("numberPositive", defaultNamespace),
    chooseOption: t("chooseOption", defaultNamespace),
    stringNoNumberValidator: t("stringNoNumberValidator", defaultNamespace),
    invalidPhoneMobile: t("invalidPhoneMobile", defaultNamespace),
    requiredPhoneMobile: t("requiredPhoneMobile", defaultNamespace),
    invalidLandlinePhone: t("invalidLandlinePhone", defaultNamespace),
    dangerWeight: t("dangerWeight", defaultNamespace),
    mustBeAdult: t("mustBeAdult", defaultNamespace),
    miniWord: t("miniWord", defaultNamespace),
    chooseRelayPoint: t("chooseRelayPoint", defaultNamespace),
  };
};
