import Api from "@middleware/api";
import { CHANNEL_CODES, ICountry } from "@middleware/types";
import { useEffect, useState } from "react";

export const useCountries = () => {
  const [countries, setCountries] = useState<ICountry[]>();

  useEffect(() => {
    const getCountries = async () => {
      const channel = await Api.cart.getChannel(CHANNEL_CODES.KITCHEN_DAILY);
      setCountries(channel.countries);
    };
    void getCountries();
  }, []);

  return countries;
};
