import { useTranslation } from "next-i18next";
import { Link } from "@components/ui";
import {
  URL_PAGE_AVIS,
  URL_PAGE_BILAN,
  URL_PAGE_METHODE,
  URL_PAGE_WOMEN,
} from "@middleware/constants";
import { SubtabMenu } from "../Header/SubtabMenu/SubtabMenu";
import style from "./Menu.module.scss";

export const DesktopMenu = () => {
  const { t } = useTranslation("header");

  const pages = [
    {
      url: URL_PAGE_METHODE,
      label: t("menu.pMethode"),
    },
    {
      url: URL_PAGE_WOMEN,
      label: t("menu.pProgrammes"),
    },
    {
      url: URL_PAGE_BILAN,
      label: t("menu.pBilan"),
    },
    {
      url: URL_PAGE_AVIS,
      label: t("menu.pAvis"),
    },
  ];

  return (
    <menu className={style.menuDesktop}>
      <nav className={style.navigation}>
        <ul>
          {pages.map((page, index) => (
            <li key={index}>
              <Link href={page.url} className="simple">
                {page.label}
              </Link>
              {index === 1 && <SubtabMenu />}
            </li>
          ))}
        </ul>
      </nav>
    </menu>
  );
};
