import Api from "@middleware/api";
import { CODE_PROMO, DEFAULT_PROMOTION } from "@middleware/constants";
import {
  getFromStorage,
  setToStorage,
} from "@middleware/helpers/global/sessions";
import { BundlePromotionDetails } from "@middleware/types";
import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

export const usePromotionDetails = () => {
  const [storagePromotion, setStoragePromotion] =
    useLocalStorage<BundlePromotionDetails>(DEFAULT_PROMOTION);
  const promotionCoupon = getFromStorage(CODE_PROMO);
  const [promotion, setPromotion] = useState(storagePromotion);

  useEffect(() => {
    const fetchPromotionDetails = async () => {
      if (
        typeof promotionCoupon === "string" &&
        promotionCoupon !== "" &&
        storagePromotion?.promotionCoupon !== promotionCoupon
      ) {
        const promotionDetails =
          await Api.catalog.getCouponPromotion(promotionCoupon);

        const superPromo = promotionDetails?.reduce(
          (maxPromo, elem) =>
            maxPromo.discountAmount > elem.discountAmount ? maxPromo : elem,
          promotionDetails[0],
        );
        if (superPromo) {
          setPromotion(superPromo);
          setStoragePromotion(superPromo);
          setToStorage(CODE_PROMO, superPromo.promotionCoupon);
        }
      }
    };

    void fetchPromotionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionCoupon]);

  return { promotion, setPromotion };
};
