import { getEnabledPaymentMethods } from "@middleware/helpers";
import { IPaymentMethod } from "@middleware/types";

export const usePaymentMethods = (paymentMethods: IPaymentMethod[]) => {
  const enabledPaymentMethods = getEnabledPaymentMethods();

  return paymentMethods
    .filter((method) => enabledPaymentMethods.includes(method.code))
    .map((paymentMethod) => ({
      label: paymentMethod.name,
      method: paymentMethod.code,
    }));
};
