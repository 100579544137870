import cn from "clsx";
import NextLink, { LinkProps } from "next/link";
import { FC, HTMLProps } from "react";
import { client as env } from "@config/env/client";
import style from "./Link.module.scss";

export const Link: FC<
  LinkProps & Omit<HTMLProps<HTMLAnchorElement>, "href">
> = ({
  as,
  children,
  href,
  onClick,
  replace,
  scroll,
  shallow,
  passHref,
  className,
  target,
  rel,
  ...rest
}) => {
  const rootClassName = cn(
    {
      [style.root]: className?.includes("primary"),
      [style.secondary]: className?.includes("secondary"),
      [style.secondaryGreen]: className?.includes("secondaryGreen"),
      [style.secondaryBlue]: className?.includes("secondaryBlue"),
    },
    className,
  );

  return (
    // @ts-expect-error no error expected
    <NextLink
      as={as}
      href={
        typeof href === "string"
          ? href.replace(env.NEXT_PUBLIC_STORE_URL, "")
          : href
      } // manage prismic urls and externe urls
      passHref={passHref}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      className={rootClassName}
      onClick={onClick}
      target={target}
      rel={rel}
      {...rest}
    >
      <>{children}</>
    </NextLink>
  );
};
