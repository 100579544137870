import Authentication from "./authentication";
import Catalog from "./catalog";
import Cart from "./cart";
import Customer from "./customer";
import MsDiet from "./msdiet";
import Bilan from "./bilan";

const Api = {
  authentication: new Authentication(),
  catalog: new Catalog(),
  cart: new Cart(),
  customer: new Customer(),
  msDiet: new MsDiet(),
  bilan: new Bilan(),
};

export default Api;
