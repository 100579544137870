import { useEffect, useState } from "react";
import { ESegmentCustomer } from "@middleware/types";
import { Content } from "@prismicio/client";
import { CODE_PROMO } from "@middleware/constants";
import { setToStorage } from "@middleware/helpers/global/sessions";
import { useCustomerSegment } from "./useCustomerSegment";
import { BarPromotionSlice } from "../../../prismicio-types";

export const usePromotionFooter = (
  homePagePrismic: Content.HomepageDocument,
) => {
  const defaultSegment = homePagePrismic.data.slices2.filter(
    (slice) => slice.primary.segment === ESegmentCustomer.PROSPECT_0,
  );
  const { customerSegment } = useCustomerSegment();
  const [promotionFooterSlices, setPromotionFooterSlices] =
    useState<BarPromotionSlice[]>(defaultSegment);

  useEffect(() => {
    const getCorrectSlices = () => {
      if (customerSegment === ESegmentCustomer.NONE) {
        return defaultSegment;
      }

      const segmentSlices = homePagePrismic.data.slices2.filter(
        (slice) => slice.primary.segment === customerSegment,
      );

      if (
        segmentSlices.length > 0 &&
        customerSegment !== ESegmentCustomer.PROSPECT_0 &&
        segmentSlices[0].primary.segment !== defaultSegment[0].primary.segment
      ) {
        const footerCode = segmentSlices[0]?.primary.promotionCoupon;
        if (footerCode !== "" && typeof footerCode === "string") {
          setToStorage(CODE_PROMO, segmentSlices[0].primary.promotionCoupon);
        }
      }

      return segmentSlices;
    };

    setPromotionFooterSlices(getCorrectSlices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegment]);

  return {
    promotionFooterSlices,
  };
};
