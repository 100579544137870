import { StylesConfig } from "react-select";

type IsMulti = false;
export const customStyles: StylesConfig<unknown, IsMulti> = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#ffffff" : "#5a008c",
    padding: "5px 10px",
    fontSize: "14px",
    backgroundColor: state.isFocused ? "#5a008c" : "#ffffff",
  }),
  input: (provided) => ({
    ...provided,
    color: "#5a008c",
    border: "none !important",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#5a008c",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "15px",
    padding: "0 5px",
    margin: 0,
    cursor: "pointer",
    color: "#5a008c",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#5a008c",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#FFFFFF",
    color: "#5a008c",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "32px",
    borderColor: "#5a008c",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 8px",
    color: "#5a008c",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "#5a008c";

    return { ...provided, opacity, transition, color };
  },
};
