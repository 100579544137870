import { getOrderLoyalty } from "@middleware/helpers";
import { IAdjustment, IOrder } from "@middleware/types";
import { useEffect, useState } from "react";

export const useLoyalty = (cart: IOrder | undefined) => {
  const [loyalty, setloyalty] = useState<IAdjustment | null>(null);

  useEffect(() => {
    if (cart) {
      const cartLoyalty = getOrderLoyalty(cart);

      setloyalty(cartLoyalty);
    }
  }, [cart]);

  return { loyalty };
};
