import {
  ICreditTransfer,
  TReferral,
  TReferralListResponse,
} from "@middleware/types";
import { generateDate, getFormattedDate } from "./utils";

export const sortCreditTransfers = (creditTransfers: ICreditTransfer[]) => {
  return creditTransfers.sort((a, b) =>
    generateDate(a.transferDate).getTime() <
    generateDate(b.transferDate).getTime()
      ? 1
      : -1,
  );
};

export const serializeReferralsList = (
  referralsList: TReferralListResponse[],
) => {
  return referralsList.map(
    (item) =>
      ({
        email: item.email,
        status: item.state,
        date: getFormattedDate(item.sponsoredAt, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        commandDate:
          item.state === "customer"
            ? getFormattedDate(item.customerSince, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "",
      }) as TReferral,
  );
};
