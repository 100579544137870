import { getOrderMeals } from "@middleware/helpers";
import { IOrderItem } from "@middleware/types";
import { useEffect, useState } from "react";
import { useCart } from "./useCart";

export const useMeals = () => {
  const { cart } = useCart();
  const [meals, setMeals] = useState<IOrderItem[]>([]);

  useEffect(() => {
    if (cart) {
      const { mealsItems } = getOrderMeals(cart);
      setMeals(mealsItems);
    }
  }, [cart]);

  return { meals };
};
