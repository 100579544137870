import { ValueOf } from "next/dist/shared/lib/constants";
import { IObject } from "../global/communsType";
import { Tab } from "../global/tabType";

export interface IResourceImage {
  id: number;
  path: string;
  altText?: string;
  width?: number;
  height?: number;
}
export interface ITaxonSlugTranslation {
  translations?: {
    [key: string]: ITaxonTranslation;
  };
}
export interface ITaxonTranslation {
  id: number;
  name: string;
  locale: string;
  slug?: string;
  description: string;
}
export interface ITaxon extends ITaxonSlugTranslation {
  id: number;
  code?: string;
  name?: string;
  position?: number;
}
export interface IProductTaxon {
  id: number;
  name?: string;
  taxon: ITaxon;
}
export interface IVariantOptionValues {
  code: string;
  optionCode: string;
  value: string;
}
export enum EProductVariantType {
  ALL = "all",
  SUBSCRIPTION = "subscription",
  ALC = "retail",
}
export interface IVariant {
  id: number;
  code: string;
  inStock: boolean;
  enabled: boolean;
  price: number;
  originalPrice: number;
  onHand: number;
  volume: number;
  weight: number;
  type: EProductVariantType;
  channelPricings: {
    [key: string]: {
      channelCode: string;
      price: number;
    };
  };
  optionValues: IVariantOptionValues[];
  subscriptionType?: {
    engagementPeriodNumber: number;
    possibleDeferral: number;
  };
  taxons?: IProductTaxon[];
}

export type IProfilPromo = 1 | 2 | 3 | 4 | 7;
export type ITypePromo = "promo1" | "promo2" | "promo3";
export interface IControlProducts {
  id: number;
  code: string;
  quantity: number;
  price: number;
  packaging: number;
  enabled: boolean;
}
export interface IControl {
  products: IControlProducts[];
}
export interface ITabControl {
  tab: Tab;
  control: IControl;
}
export interface IFilter {
  code: string;
  name: string;
  image: string;
}
export type IChannel = {
  id: number;
  code: string;
  date?: Date;
};
export interface IBundleAttribute {
  localeCode: string | null;
  attributeName: string;
  attributeCode: string;
  value: number | string | string[] | boolean | IObject | IObject[];
}
export interface IProductResponse {
  id: number;
  code: string;
  name: string;
  shortDescription: string;
  description: string;
  conditioning: string;
  usageTips: string;
  ingredients: string;
  nutritionalData: string;
  images: IResourceImage[];
  attributes?: IBundleAttribute[];
  variants: IVariant[];
  productTaxons: IProductTaxon[];
  packaging: number | undefined;
  enabled: boolean;
  desktopPosition?: number;
  mobilePosition?: number;
  isNew?: boolean;
  priority?: number;
  taxons?: string[];
}
export interface IMenuResponse {
  id: number;
  code: string;
  slot: string;
  items: IMenuItemResponse[];
}
export interface IMenuItemResponse {
  id: number;
  new: boolean;
  priority: number;
  product: IProductResponse;
}
export interface IConfigurationResponse {
  id: number;
  priority: number;
  channel: IChannelConfiguration;
  taxon: ITaxonConfiguration;
  name: string;
  tunnelType: TConfigurationTunnelType;
}
export interface IChannelConfiguration {
  code: string;
  name: string;
}
export interface ITaxonConfiguration {
  code: string;
  name: string;
}

export interface IProgram {
  code: string;
  objectif: string;
  productTaxon: EProgramType;
  price: number;
  programDays: number;
  hasSnacks: boolean;
  hasBreakfasts: boolean;
  breakfastsAndSnacks: boolean;
  extraTaxonConfiguration?: IExtraTaxonConfiguration;
  extraTaxonQuantity?: IExtraTaxonConfiguration;
  mealsNumber: number;
  engagement: number;
  defaultCouponCode: string | null;
}

export interface IProduct {
  id: number;
  code: string;
  codeVariant: string;
  price: number;
  originalPrice?: number;
  mealsNumber: number;
  isDefault: boolean;
  checkoutEnabled: boolean;
  name: string;
  shortDescription: string;
  description: string;
  image: string;
  isNew: boolean;
  priority: number;
  foodPreference: string[];
  nutriscore: string[];
  ingredients: string;
  nutritional: string;
  defaultCouponCode: string | null;
  preparation: string;
  packaging: number;
  desktopPosition: number;
  mobilePosition: number;
  volume: number | null;
  weight: number | null;
  storageInstructions: string[];
  extraTaxonConfiguration?: IExtraTaxonConfiguration;
  extraTaxonQuantity?: IExtraTaxonConfiguration;
  programDays: number;
  taxons?: string[];
  recommendedQuantity: number;
  variants?: IVariant[];
  productTaxons?: IProductTaxon[];
  optionValues?: IVariantOptionValues[];
  enabled: boolean;
}
export interface IExtraTaxonConfiguration {
  values: IObject[];
}
export interface IConfiguration {
  label: string;
  id: string;
  required: boolean;
  priority: number;
  visited?: boolean;
  tunnelType?: TConfigurationTunnelType;
}
export type TConfigurationTunnelType = "subscription" | "retail";
export enum ERuleTypeValue {
  NTH = "nth",
}
export enum ERuleType {
  RULE_FIRST_ORDER = "nth_customer_order",
  RULE_SEGMENT = "segment",
}
export type IPromotionRuleConfiguration = Record<ERuleTypeValue, number>;
export interface IPromotionCouponRuleResponse {
  id: number;
  type: ERuleType;
  configuration: IPromotionRuleConfiguration;
  promotion: string;
}

export enum DiscountTypeValue {
  AMOUNT = "amount",
  PERCENTAGE = "percentage",
}
export type IPromotionActionConfiguration = Record<DiscountTypeValue, number>;
export interface TPromotionActionConfiguration {
  amount: number;
  percentage: number;
  variants_filter?: {
    variants: string[];
  };
}

export type IPromotionCouponActionResponse =
  | IPromotionCouponDiscountActionResponse
  | IPromotionCouponAssigningActionResponse;

export enum CHANNEL_CODES {
  KITCHEN_DAILY = "KITCHEN_DAILY",
  DIETBON = "DIETBON",
}

export interface IPromotionCouponDiscountActionResponse {
  actionType: "discount";
  id: number;
  type: DiscountType;
  configuration:
    | Record<CHANNEL_CODES, TPromotionActionConfiguration>
    | TPromotionActionConfiguration;
  promotion: string;
}

export interface IPromotionCouponAssigningActionResponse {
  actionType: "assigner";
  id: number;
  type: AssigningType;
  configuration: Record<string, string>;
  promotion: string;
}

export interface IPromotionCouponsResponse {
  channels: string[];
  id: number;
  code: string;
  name: string;
  description: string;
  priority: number;
  exclusive: true;
  usageLimit: number;
  used: number;
  startsAt: string;
  endsAt: string;
  couponBased: true;
  coupons: string[];
  rules: IPromotionCouponRuleResponse[];
  actions: IPromotionCouponActionResponse[];
  appliesToDiscounted: true;
  createdAt: string;
  updatedAt: string;
}
export interface BundlePromotion {
  bundleCode: string;
  promotionCoupon: string;
  discountType: DiscountType;
  discountAmount: number;
  firstOrder: boolean;
  groupCode: string | null;
  subscriptionTypeCode: string | null;
  variants: string[];
}

export enum DiscountType {
  UNIT_PERCENTAGE = "unit_percentage_discount",
  UNIT_FIXED = "unit_fixed_discount",
  VARIANT_FIXED = "variant_unit_fixed_discount",
  ORDER_PERCENTAGE = "order_percentage_discount",
  ORDER_FIXED = "order_fixed_discount",
}

export enum AssigningType {
  GROUP_ASSIGNER = "assign_customer_to_group",
  SUBSCRIPTION_ASSIGNER = "subscription",
}

export type BundlePromotionDetails = Omit<BundlePromotion, "bundleCode">;

export type BundleCodePromotionMap = Record<string, BundlePromotionDetails>;

export enum EProductType {
  PRODUCT_MEALS = "repas",
  PRODUCT_EXTRAS = "extras",
  PRODUCT_OFFERED = "offered",
}

export interface IObjectif {
  code: string;
  label: string;
  value: number;
}

export enum EProgramType {
  ORIGINAL = "original",
  OPTIMUM = "optimum",
  ECO = "eco",
  STAB = "stabilisation",
  NONE = "none",
}
export type TProgramType = ValueOf<typeof EProgramType>;

export enum EMealType {
  PDEJ = "Petit-déjeuner",
  DEJ = "Déjeuner",
  SNACK = "Collation",
  DINNER = "Dîner",
}

export type IOptionCode = "numberOfDays" | "breakfastsAndSnacks";
export type IOptionValue = {
  label: string;
  value: number | boolean;
};
export type IOptions = {
  tab: string;
  code: IOptionCode;
  values: IOptionValue[];
};
export type IOptionsValues = {
  [key in IOptionCode]: any;
};

export type TProgramInfosList = {
  key: string;
  data?: IObject;
}[];

export type IBundleInfo = {
  typeProgram: EProgramType | "";
  daysProgram:
    | string
    | number
    | boolean
    | string[]
    | IObject
    | IObject[]
    | undefined;
};
