import { loadPayzenLibrary } from "@middleware/helpers/global/payment";
import { useEffect, useState } from "react";

export const usePayzenLibrary = () => {
  const [payzenLibrary, setPayzenLibrary] = useState<KR>();

  useEffect(() => {
    const loadPayzen = async () => {
      const KR = await loadPayzenLibrary();
      setPayzenLibrary(KR);
    };
    void loadPayzen();
  }, []);

  return { payzenLibrary, setPayzenLibrary };
};
