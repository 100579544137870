import { useUI } from "@middleware/hooks";
import style from "./Hamburger.module.scss";

export const Hamburger = () => {
  const { displayDropdown, openDropdown, closeDropdown } = useUI();

  return (
    <div className={style.hamburger}>
      <a
        href="!#"
        onClick={(e) => {
          e.preventDefault();
          displayDropdown ? closeDropdown() : openDropdown();
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>
  );
};
