import React, { useEffect, useState } from "react";
import style from "./CircularProgress.module.scss";

interface Props {
  value: number;
  size?: number;
  onChange: (value: number) => void;
}
export const CircularProgress = ({ value, size = 63, onChange }: Props) => {
  const [offset, setOffset] = useState(0);
  const circumference = 2 * Math.PI * size;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressOffset = circumference - (progress / 100) * circumference;
    setOffset(progressOffset);
  }, [progress, circumference]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + 1;
        if (newProgress > value) {
          clearInterval(interval);

          return value;
        }

        return newProgress;
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    onChange(progress);
  }, [progress, onChange]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="138"
      height="138"
      viewBox="0 0 138 138"
      fill="none"
      className={style.progress}
    >
      <circle
        cx="69"
        cy="69"
        r={size}
        fill="none"
        stroke="#C1A0D4"
        strokeWidth="12"
      />
      <circle
        className={style.progressStroke}
        cx="69"
        cy="69"
        r={size}
        fill="none"
        stroke="#55047D"
        strokeWidth="12"
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
    </svg>
  );
};
