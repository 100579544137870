import {
  CODE_TAB_ORDERS,
  CODE_TAB_PROGRAM,
  CODE_TAB_SPONSOR,
  CODE_TAB_TRACKING,
  URL_PAGE_ALC,
  URL_PAGE_CUSTOMER_CLUB,
  URL_PAGE_CUSTOMER_CREDIT_BALANCE,
  URL_PAGE_CUSTOMER_CURRENT_PROGRAM,
  URL_PAGE_CUSTOMER_CURRENT_PROGRAM_DETAIL,
  URL_PAGE_CUSTOMER_DIET_CHAT,
  URL_PAGE_CUSTOMER_DIETITIAN,
  URL_PAGE_CUSTOMER_EARN_CREDIT,
  URL_PAGE_CUSTOMER_GUIDE,
  URL_PAGE_CUSTOMER_INFOS,
  URL_PAGE_CUSTOMER_ORDERS,
  URL_PAGE_CUSTOMER_ORDERS_COMING,
  URL_PAGE_CUSTOMER_ORDERS_PAST,
  URL_PAGE_CUSTOMER_WEIGHT,
  URL_PAGE_PROGRAM,
  URL_PAGE_SPONSOR,
  URL_PAGE_TRACKING,
} from "@middleware/constants";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useAccountPage = () => {
  const { pathname } = useRouter();
  const [activeTab, setActiveTab] = useState<string>(CODE_TAB_ORDERS);

  useEffect(() => {
    switch (pathname) {
      case URL_PAGE_CUSTOMER_ORDERS:
      case URL_PAGE_CUSTOMER_ORDERS_COMING:
      case URL_PAGE_CUSTOMER_ORDERS_PAST:
        setActiveTab(CODE_TAB_ORDERS);
        break;
      case URL_PAGE_SPONSOR:
      case URL_PAGE_CUSTOMER_CREDIT_BALANCE:
      case URL_PAGE_CUSTOMER_EARN_CREDIT:
        setActiveTab(CODE_TAB_SPONSOR);
        break;
      case URL_PAGE_PROGRAM:
      case URL_PAGE_CUSTOMER_CURRENT_PROGRAM:
      case URL_PAGE_CUSTOMER_CURRENT_PROGRAM_DETAIL:
      case URL_PAGE_ALC:
      case URL_PAGE_CUSTOMER_INFOS:
      case URL_PAGE_CUSTOMER_GUIDE:
        setActiveTab(CODE_TAB_PROGRAM);
        break;
      case URL_PAGE_TRACKING:
      case URL_PAGE_CUSTOMER_WEIGHT:
      case URL_PAGE_CUSTOMER_DIETITIAN:
      case URL_PAGE_CUSTOMER_CLUB:
      case URL_PAGE_CUSTOMER_DIET_CHAT:
        setActiveTab(CODE_TAB_TRACKING);
        break;
      default:
        break;
    }
  }, [pathname]);

  return { activeTab, setActiveTab };
};
