import { EShipmentMethod, LOAD_CART } from "@middleware/constants";
import { IOrder } from "@middleware/types/commerce/salesType";
import { CHANNEL_CODES } from ".";

export interface IPromo {
  code: string;
}
export interface IBillingAddress {
  firstName: string;
  lastName: string;
  street: string;
  countryCode: string;
  city: string;
  postcode: string;
  company: string;
  phoneNumber: string;
  mobilePhone: string;
  provinceCode: string | null;
  complement?: string;
}
export interface IShippingAddress {
  firstName: string;
  lastName: string;
  countryCode: string;
  street: string;
  city: string;
  postcode: string;
  company: string;
  phoneNumber: string;
  mobilePhone: string;
  provinceCode: string | null;
  complement?: string;
  additionalInformation: string;
  deliveryInstructions: string | null;
  provinceName: string | null;
}
export interface IOrderAddress {
  shippingAddress: IShippingAddress;
  billingAddress: IBillingAddress;
  useDifferentBillingAddress?: boolean;
  updateUpcomingOrders?: boolean;
}
export interface ICartInfo {
  cart: IOrder | undefined;
}
export interface ICartState extends ICartInfo {
  loadCart: () => Promise<IOrder | null>;
  getNewCart: (apply?: boolean) => Promise<IOrder | null>;
  setCart: (cart: IOrder) => void;
}
export interface ILocalStorageCart {
  token: string;
}
export interface ActionCartType extends ICartInfo {
  type: typeof LOAD_CART;
}
export interface TshippingMethodConfiguration {
  amount: number;
}
export interface IShippingMethod {
  id: number;
  code: string;
  type: EShipmentMethod;
  position: number;
  name: string;
  description: string;
  configuration: Record<CHANNEL_CODES, TshippingMethodConfiguration>;
}

export interface IShipmentDetail {
  tariffLevel: string;
  transactionID?: string;
  rank: number;
  deliverySlotCode: string;
}
export interface IDeliverySlot {
  date: string;
  details: IShipmentDetail;
}
export interface IRelayPointHoraire {
  debut: string;
  fin: string;
}
export interface IRelayPoints {
  actif: boolean;
  adresse1: string;
  adresse2: string;
  adresse3: string;
  codePays: string;
  codePostal: string;
  coordGeolocalisationLatitude: string;
  coordGeolocalisationLongitude: string;
  identifiant: string;
  localite: string;
  nom: string;
  typeDePoint: string;
  urlGoogleMaps: string;
  listeHoraireOuverture: {
    horairesAsString: string | undefined;
    jour: number;
    listeHoraireOuverture: IRelayPointHoraire[];
  }[];
}
export interface IMapAddressDays {
  day: string;
  value: string | undefined;
}
export interface IMapAddress {
  address: string;
  longitude: number;
  latitude: number;
  identifiant: string;
  schedules: IMapAddressDays[];
  nom: string;
}

export interface IShippingMethods {
  shipping_methods: IShippingMethod[];
  delivery_slots?: IDeliverySlot[];
  chronopost_relay_points?: IRelayPoints[];
}
export interface ISchedule {
  [key: string]: IShippingMethods;
}

export interface IShippingMethodsResponse {
  schedule: ISchedule;
  transactionID: string;
}

export interface ICountry {
  code: string;
  name: string;
  hasProvinces: boolean;
}
export interface IChannelResponse {
  code: string;
  name: number;
  countries: ICountry[];
}
export interface IPaymentMethod {
  id: number;
  code: EPaymentMethods;
  position: number;
  name: string;
  description: string;
}
export enum EPaymentMethods {
  CREDIT_CARD = "CB_DB",
  SEPA = "SDD_DB",
  PAYBOX = "CB_PAYBOX_3D_DB", // sans 3D secure
  PAYBOX_3D = "CB_PAYBOX_DB", // avec 3D secure
}

export enum EPayZenFormAction {
  PAYMENT = "payment",
  UPDATE = "update",
}

export enum ECartType {
  ALC = "retail",
  RESUME = "subscription_resume",
  SUBSCRIPTION = "subscription",
}
