import ContentLoader from "react-content-loader";
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";
import SyncLoader from "react-spinners/SyncLoader";

type Variant = "content" | "clip" | "sync" | "fade";
export interface LoadingType {
  type?: Variant;
  color?: string;
}

export const Loader: React.FC<LoadingType> = (props) => {
  const { type, color } = props;
  const defalutColor = color !== undefined ? color : "#fff";

  return (
    <>
      {type === "content" ? (
        <ContentLoader
          speed={2}
          width={340}
          height={100}
          viewBox="0 0 340 100"
          backgroundColor={color}
          foregroundColor={`${color ?? "#fff"}40`}
        >
          <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
          <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
          <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
          <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
          <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
          <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
          <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
          <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
        </ContentLoader>
      ) : type === "clip" ? (
        <ClipLoader color={defalutColor} />
      ) : type === "sync" ? (
        <SyncLoader color={defalutColor} />
      ) : (
        <FadeLoader color={defalutColor} />
      )}
    </>
  );
};
