import {
  ECalendarHourStatus,
  ICalendarDataApi,
  ICalendarDay,
  ICalendarHour,
  IObject,
  TCalendarHourStatus,
} from "@middleware/types";
import { isSunday } from "date-fns";
import { checkIfTimeHasPassed } from "../global/agenda";

export const serializeCalendarData = (
  apiData: ICalendarDataApi,
): ICalendarDay[] => {
  const slots = apiData.calendar;

  if (Object.keys(slots).length === 0) return [];

  const result: ICalendarDay[] = [];
  Object.keys(slots).map((datetime) => {
    const splitedDateTime = datetime.split(" ");
    const date = splitedDateTime[0];
    const time = splitedDateTime[1];
    const hour = {
      value: time.slice(0, 5),
      status: slots[datetime] as TCalendarHourStatus,
    };
    const isNotClosed = slots[datetime] !== ECalendarHourStatus.CLOSED;
    const dateIndex = result.findIndex((day) => day.value === date);

    if (dateIndex !== -1) {
      if (isNotClosed) {
        result[dateIndex].hours.push(hour);
      }
    } else {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const dateToCompare = new Date(date).setHours(0, 0, 0, 0);

      if (dateToCompare >= currentDate && !isSunday(dateToCompare)) {
        const calendarDay: ICalendarDay = {
          value: date,
          hours: isNotClosed ? [hour] : [],
          collapsed: false,
          page: 1,
        };

        result.push(calendarDay);
      }
    }

    return date;
  });

  return result;
};

export const serializeCalendarHoursData = (
  hoursData: IObject,
  date: string,
) => {
  return Object.entries(hoursData).reduce((hoursResult, [time, status]) => {
    const passedTime = checkIfTimeHasPassed(date, time);
    if (!passedTime) {
      hoursResult.push({
        value: time.slice(0, 5),
        status: status as TCalendarHourStatus,
      });
    }

    return hoursResult;
  }, [] as ICalendarHour[]);
};
