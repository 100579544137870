import { StateMessaging } from "@middleware/types";

export const globalStatusInitialState: StateMessaging = {
  message: {
    text: null,
    type: null,
  },
  setMessage: () => undefined,
  cleanMessage: () => undefined,
};
