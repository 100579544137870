import cn from "clsx";
import React, { CSSProperties, FunctionComponent } from "react";
import styles from "./RichText.module.scss";

interface IRichText {
  children: React.ReactNode | string;
  className?: string;
  style?: CSSProperties;
  dataTest?: string;
}

export const RichText: FunctionComponent<IRichText> = ({
  style,
  className = "",
  children,
  dataTest = "",
}) => {
  return (
    <div
      className={cn(styles.root, className)}
      data-testid={dataTest}
      style={style}
    >
      {children}
    </div>
  );
};
