import { useAlc, useCart } from "@middleware/hooks";
import {
  URL_PAGE_CART,
  URL_PAGE_CUSTOMER_ORDERS_COMING,
  URL_PAGE_DURATION,
  URL_PAGE_RESTART_PROGRAM,
} from "@middleware/constants";
import {
  isCustomerPaused,
  isCustomerSubscribed,
  isStabOrder,
} from "@middleware/helpers";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { EOrderType } from "@middleware/types";
import { useCustomer } from "./useCustomer";

export const useRedirectIfSubscribed = () => {
  const router = useRouter();
  const { cart } = useCart();
  const { pathname } = useRouter();
  const { customer } = useCustomer();
  const { isAlc } = useAlc(cart);
  const isRestartCart = cart ? cart.type === EOrderType.RESUME : false;
  const isStab = isStabOrder(cart);

  useEffect(() => {
    const pagesArray = [URL_PAGE_CART, URL_PAGE_DURATION];

    const checkUserSubscription = async () => {
      if (
        !customer ||
        !cart ||
        isAlc !== false ||
        !pagesArray.includes(pathname) ||
        (isStab && pathname === URL_PAGE_CART)
      )
        return;

      if (isCustomerPaused(customer)) {
        if (!isRestartCart) {
          await router.push(
            {
              pathname: URL_PAGE_RESTART_PROGRAM,
              query: {
                alreadySubscribed: "true",
              },
            },
            URL_PAGE_RESTART_PROGRAM,
          );
        }
      } else if (isCustomerSubscribed(customer)) {
        await router.push(
          {
            pathname: URL_PAGE_CUSTOMER_ORDERS_COMING,
            query: {
              alreadySubscribed: "true",
            },
          },
          URL_PAGE_CUSTOMER_ORDERS_COMING,
        );
      }
    };
    void checkUserSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, pathname, cart, isAlc, router]);
};
