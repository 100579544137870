import { IProduct } from "@middleware/types";
import { useCallback } from "react";
import { useTranslation } from "next-i18next";
import { RichText, Text } from "@components/ui";

interface Props {
  product: IProduct;
}

export const IngredientsAdditionalInformation = ({ product }: Props) => {
  const { t } = useTranslation();
  const getPackagingText = useCallback(() => {
    switch (true) {
      case product.packaging === 1:
        return t("popProduct.soldInPackagingEqualTo1");
      case product.packaging > 1:
        return t("popProduct.soldInPackagingMoreThan1", {
          count: product.packaging,
        });
      default:
        return "";
    }
  }, [product.packaging, t]);

  return (
    <RichText>
      {product.storageInstructions.length > 0 && (
        <Text variant="p" className="no-margin">
          <Text
            variant="span"
            html={`${t("popProduct.storageInstructions")} : `}
          />
          <Text variant="span" html={product.storageInstructions[0]} />
        </Text>
      )}
      <Text variant="p" className="no-margin">
        <Text variant="span" html={getPackagingText()} />
      </Text>
      {product.weight !== null && (
        <Text variant="p">
          <Text
            variant="span"
            html={`${t("popProduct.netWeightPerUnit", {
              weight: product.weight,
            })}`}
          />
        </Text>
      )}
      <Text variant="p" className="no-margin">
        {t("popProduct.ingredients")} :
      </Text>
      <Text variant="p" html={product.ingredients} />
    </RichText>
  );
};
