import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FormProps } from "@middleware/types";

export const GenericForm = ({
  renderForm,
  onSubmitForm,
  defaultValues,
  schema,
  onWatch,
  mode = "onBlur",
}: FormProps) => {
  const checkSchema = schema ? schema : yup.object().shape({});
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: mode,
    defaultValues: defaultValues,
    resolver: yupResolver(checkSchema),
  });
  watch();

  useEffect(() => {
    if (onWatch) {
      const subscription = watch((value) => onWatch(value));

      return () => subscription.unsubscribe();
    }
  }, [watch, onWatch]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
      {renderForm({
        register,
        errors,
        isSubmitting,
        isSubmitted,
        control,
        reset,
        watch,
        setValue,
      })}
    </form>
  );
};
