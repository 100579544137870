import cn from "clsx";
import { useTranslation } from "next-i18next";
import { Link } from "@components/ui";
import { useSteps } from "@middleware/hooks/useSteps";
import style from "./StepsProgrammes.module.scss";

export const StepsProgrammes = () => {
  const { t } = useTranslation();
  const { currentStep, previousStepsUrl } = useSteps();
  const steps = [
    {
      step: 1,
      label: t("buttons.program"),
    },
    {
      step: 2,
      label: t("buttons.meals"),
    },
    {
      step: 3,
      label: t("buttons.payment"),
    },
  ];

  return (
    <div className={style.stepsContainer}>
      <div className={style.menuProg}>
        <ul className={style.boxMenu}>
          {currentStep !== null &&
            steps.map((item, stepIndex) => {
              const urlStep =
                stepIndex + 1 < currentStep ? previousStepsUrl[stepIndex] : "";

              return (
                <li
                  key={stepIndex}
                  className={cn(style.stepBox, {
                    [style.activeStep]: item.step === currentStep,
                    [style.prevStep]: item.step < currentStep,
                    [style.nextStep]: item.step - 1 === currentStep,
                  })}
                  data-step={stepIndex + 1}
                >
                  <label>
                    {urlStep !== "" ? (
                      <Link href={urlStep} className="simple">
                        {item.label}
                      </Link>
                    ) : (
                      <span>{item.label}</span>
                    )}
                  </label>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
