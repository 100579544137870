import cn from "clsx";
import { useRouter } from "next/router";
import { FC, MouseEventHandler, SyntheticEvent } from "react";
import { Logo, Menu } from "@components/commun";
import { URL_PAGE_SUCCESS } from "@middleware/constants";
import { Link } from "@components/ui";
import { StepsProgrammes } from "@components/catalog/steps/StepsProgrammes";
import { PictoPhone } from "../PictoPhone/PictoPhone";
import { PictoAccount } from "../PictoAccount/PictoAccount";
import { PictoCart } from "../PictoCart/PictoCart";
import styles from "./NavBar.module.scss";

export interface Props {
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
  isUserInProgramFunnel: boolean;
  isFullScreenPage?: boolean;
}

export const NavBar: FC<Props> = ({
  showModal,
  logout,
  isUserInProgramFunnel,
  isFullScreenPage = false,
}) => {
  const { pathname } = useRouter();
  const showHeader = !isUserInProgramFunnel && pathname !== URL_PAGE_SUCCESS;

  return (
    <>
      <div
        className={cn(styles.brand, {
          [styles.isFunnel]: isUserInProgramFunnel,
        })}
      >
        <Link href="/" className="simple">
          <Logo />
        </Link>
      </div>

      {showHeader && (
        <Menu
          isFullScreenPage={isFullScreenPage}
          device="desktop"
          showModal={showModal}
          logout={logout}
        />
      )}

      {isUserInProgramFunnel && <StepsProgrammes />}

      <div
        className={cn(styles.rightNav, {
          [styles.isFunnel]: isUserInProgramFunnel,
        })}
      >
        <PictoPhone />
        {!isFullScreenPage && (
          <>
            <PictoAccount showModal={showModal} logout={logout} />
            <PictoCart />
          </>
        )}
      </div>
    </>
  );
};
