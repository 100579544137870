import cn from "clsx";
import React from "react";
import { RichTextField } from "@prismicio/client";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { TitlePage } from "@components/commun/TitlePage/TitlePage";
import { ETitleLineStyles } from "@middleware/types";
import { ImageField } from "@prismicio/types";
import style from "./PageHead.module.scss";

interface Props {
  image: ImageField;
  title: RichTextField;
  titleSize?: "sm" | "lg";
}
export const PageHead = ({ title, image, titleSize = "sm" }: Props) => {
  return (
    <div className={style.head}>
      <PrismicImage className={cn(style.image)} field={image} alt="" />
      <TitlePage
        underline={ETitleLineStyles.STYLE_2}
        className={cn(style.title, style[titleSize])}
      >
        <PrismicRichText field={title} />
      </TitlePage>
    </div>
  );
};
