import { HOURS_PER_PAGE } from "@middleware/constants";
import {
  ESubscriptionState,
  ICalendarDay,
  ICalendarHour,
  ICustomer,
  TAppointment,
  TAppointmentApiData,
  TDiet,
} from "@middleware/types";
import { getBasicFormattedDate, getFormattedTime } from "./utils";

export const hasFinishedSubscription = (customer: ICustomer | undefined) => {
  if (customer === undefined) return false;

  return customer.currentSubscription?.state === ESubscriptionState.CLOSED;
};

export const hasPausedSubscription = (customer: ICustomer | undefined) => {
  if (customer === undefined) return false;

  return customer.currentSubscription?.state === ESubscriptionState.PAUSED;
};

export const getRemainingMinutes = (appointment: TAppointment): number => {
  const appointmentDate = new Date(
    `${appointment.date} ${appointment.startTime}`,
  );
  const currentTime = new Date();
  const timeDifference = appointmentDate.getTime() - currentTime.getTime();
  let MunitesDifference = timeDifference / (1000 * 60);
  if (MunitesDifference < 0) MunitesDifference = 0;

  return Math.round(MunitesDifference);
};
export const add20Minutes = (time: string | null | undefined): string => {
  if (typeof time !== "string") return "";

  const [hours, minutes] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes + 20;
  const newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;
  const formattedTime = `${String(newHours).padStart(2, "0")}:${String(
    newMinutes,
  ).padStart(2, "0")}`;

  return formattedTime;
};

export const isCollapsed = (day: ICalendarDay): boolean => {
  return day.hours.length <= day.page * HOURS_PER_PAGE;
};

export const getActiveHours = (day: ICalendarDay): ICalendarHour[] => {
  return [...day.hours].splice(0, day.page * HOURS_PER_PAGE);
};

export const hasMoreSlots = (day: ICalendarDay): boolean => {
  return day.hours.length > day.page * HOURS_PER_PAGE;
};

export const checkIfDayHasPassed = (day: string) => {
  const currentDate = new Date();
  const selectedDay = new Date(
    `${day} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}:${currentDate.getMilliseconds()}`,
  );
  if (selectedDay < currentDate) {
    return true;
  }

  return false;
};
export const checkIfTimeHasPassed = (day: string, hour: string) => {
  const currentDate = new Date();
  const selectedDay = new Date(
    `${day} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}:${currentDate.getMilliseconds()}`,
  );
  const minHours = parseInt(hour.split(":")[0]);

  if (
    selectedDay.getTime() === currentDate.getTime() &&
    currentDate.getHours() >= minHours
  ) {
    return true;
  }

  return false;
};

export const serializeAppointmentData = (appointment: TAppointmentApiData) => {
  const formattedDate = appointment.current_appointment_date ?? "";
  const date = getBasicFormattedDate(new Date(formattedDate));
  const time = getFormattedTime(formattedDate, true);

  const newAppointment: TAppointment = {
    date: date,
    startTime: time,
    endTime: add20Minutes(time),
    id: appointment.current_appointment.id ?? 0,
  };
  const diet: TDiet = {
    lastAppointment: appointment.last_appointment,
    isFirstAppointment: appointment.is_first_appointment,
    currentAppointmentDate: appointment.current_appointment_date,
    canTake: appointment.can_take,
    hasDiet: appointment.has_diet,
    hasCurrentAppointment: appointment.has_current_appointment,
    appointmentCredit: appointment.appointment_credit,
    cancelledAppointmentCount: appointment.cancelled_appointment_count,
    currentAppointment: newAppointment,
    diet: appointment.diet,
  };

  return diet;
};
