import WrapApi from "@middleware/helpers/api/wrapApi.class";
import {
  serializeObjectiveWeightBilan,
  serializeResultBilan,
} from "@middleware/helpers/global/bilan";
import {
  TObjectiveWeightBilan,
  TResponseObjectiveWeightBilan,
  TResponseResultBilan,
  TResultBilan,
  TResultsBilanData,
} from "@middleware/types";
import { AxiosResponse } from "axios";

export default class Bilan extends WrapApi {
  getResultBilan = async (
    customerId: number,
  ): Promise<TResultBilan | undefined> => {
    const result = await this.getResource(
      `shop/customers/${customerId}/evaluation`,
    );

    if (result.status !== 200) return;

    return serializeResultBilan(
      result.data as TResponseResultBilan,
    ) as TResultBilan;
  };

  getObjectiveWeight = async (
    height: number,
    currentWeight: number,
    desiredWeight: number,
  ): Promise<TObjectiveWeightBilan | undefined> => {
    const result = await this.postResource(
      `shop/customers/evaluation/recommended-bundle`,
      {
        height: height,
        current_weight: currentWeight,
        desired_weight: desiredWeight,
      },
      null,
    );

    if (result.status !== 200) return;

    return serializeObjectiveWeightBilan(
      result.data as TResponseObjectiveWeightBilan,
    ) as TObjectiveWeightBilan;
  };

  saveBilanResult = async (
    data: TResultsBilanData,
    utmParams?: { [key: string]: string | unknown | null },
  ): Promise<AxiosResponse> => {
    return await this.postResource(
      `shop/customers/evaluation/`,
      data,
      null,
      utmParams,
    );
  };
}
