import {
  ESubscriptionState,
  ICustomer,
  TDietitianData,
} from "@middleware/types";
import { Content } from "@prismicio/client";
import { client as env } from "@config/env/client";

export const getDietitianData = (
  customer: ICustomer,
  Dietitian: Content.MesDieteticiennesDocument,
): TDietitianData => {
  const data: TDietitianData = {};

  switch (customer.currentSubscription?.state) {
    case ESubscriptionState.ON_GOING:
      data.text = Dietitian.data.blockTextActiveSubscription;
      break;

    case ESubscriptionState.PAUSED:
      data.text = Dietitian.data.blockTextPausedSubscription;
      break;

    case ESubscriptionState.UNPAID:
      data.text = Dietitian.data.blockTextUnpaidSubscription;
      break;

    default:
      data.text = Dietitian.data.blockTextProspect;
      break;
  }

  return data;
};

export const getDietitianFeature = (path: string) => {
  return env.NEXT_PUBLIC_MS_DIET_URL + "/uploads/images/diet/" + path;
};
