import { LOAD_CART } from "@middleware/constants";
import { ActionCartType, ICartInfo } from "@middleware/types/commerce";

export const cartReducer = (state: ICartInfo, action: ActionCartType) => {
  switch (action.type) {
    case LOAD_CART:
      return { ...state, cart: action.cart };

    default:
      throw new Error(`Unhandled action type`);
  }
};
