export const contextUiInitialState = {
  isLoading: false,
  displayDropdown: false,
  displayModal: false,
  modalView: "SIGNUP_VIEW",
  productToShow: undefined,
  setModalView: () => undefined,
  openModal: () => undefined,
  closeModal: () => undefined,
  openDropdown: () => undefined,
  closeDropdown: () => undefined,
  hideLoader: () => undefined,
  showLoader: () => undefined,
};
